<script setup>
import { ref } from 'vue';
import SingleView from './single-view';

const props = defineProps({
  views: {
    type: Array,
    default: () => [],
  },
  start: {
    type: String,
    default: '',
  },
});

const activeTab = ref(props.start);
</script>

<template>
  <div class="tabbed-view">
    <q-tabs
      v-model="activeTab"
      active-color="brand-light"
      align="left"
      class="text-brand-dark"
    >
      <q-tab
        v-for="(view, index) in views"
        :key="index"
        :name="view.slug"
        :label="view.menu"
      />
    </q-tabs>
    <q-tab-panels
      v-model="activeTab"
      animated
    >
      <q-tab-panel
        v-for="(view, index) in views"
        :key="index"
        :name="view.slug"
      >
        <SingleView :info="view"/>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.tabbed-view { margin-top: 1rem; }
</style>
