<script setup>
/* eslint import/no-unresolved: */
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import { Notify } from 'quasar';
import PageLayout from '@/components/layouts/page-layout';
import SiteHeader from '@/components/widgets/site-header';
import SidebarBrands from '@/components/widgets/sidebar-brands';
import SingleView from './single-view';
import TabbedView from './tabbed-view';

const store = useStore();
const route = useRoute();

const views = ref([]);
const startTab = ref('');
const loading = ref(true);
const loadSettings = {
  loadingMessage: 'loading info...',
  height: '70vh',
};
const loadData = (to) => {
  const info = to ? to.params.info : route.params.info;
  if (info) {
    // loading.value = true;
    store.dispatch('getInfoPage', info)
      .then((response) => {
        views.value = response;
        startTab.value = info;
        loading.value = false;
      })
      .catch((error) => {
        Notify.create({ type: 'app-error', message: error.message });
        loading.value = false;
      });
  }
};
onBeforeRouteUpdate(async (to, from, next) => {
  await loadData(to);
  return next();
});
loadData();
</script>

<template>
  <SiteHeader />
  <PageLayout
    :loading="loading"
    :load-settings="loadSettings"
    sidebar-right
  >
    <template #mainContent>
      <div
        v-if="views.length"
        key="content"
        class="info-content"
      >
        <SingleView
          v-if="views.length === 1"
          :info="views[0]"
        />
        <TabbedView
          v-else
          :views="views"
          :start="startTab"
        />
      </div>
    </template>
    <template #rightSidebar>
      <SidebarBrands />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

</style>
